import React, { useState, useEffect } from 'react';
import '../css/register.css';
import { registerUser } from './api';
import { Link } from 'react-router-dom';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const Register = ({ setIsLoggedIn }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [acceptPolicy, SetAcceptPolicy] = useState(false);

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError('');  // Ta bort felmeddelandet efter 5 sekunder
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!acceptPolicy) {
            setError('Du måste godkänna integritetspolicyn för att kunna registrera dig.');
            return;
        }

        try {
            const response = await registerUser(firstName, lastName, email, password);
            console.log(response.data);
            if (response && response.data) {
                //setIsLoggedIn(true); // Använd endast om API-svaret är korrekt
                setSuccessMessage('Registreringen lyckades!');
            } else {
                setError('Ett oväntat fel uppstod, försök igen senare.');
            }
        } catch (err) {
            setError('Registreringen misslyckades, kontrollera informationen och försök igen.');
            console.error(err.response ? err.response.data : err.message);
        }
    };

    return (
        <div className="register-page">
            <div className="register-container">
                <form onSubmit={handleSubmit}>
                    <h1>Registrera</h1>
                    <input
                        type="text"
                        placeholder="Förnamn"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        autoComplete="off"
                    />
                    <input
                        type="text"
                        placeholder="Efternamn"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        autoComplete="off"
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="off"
                    />
                    <input
                        type="password"
                        placeholder="Lösenord"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                    />

                    <div className="policy-container">
                        <input
                            type="checkbox"
                            checked={acceptPolicy}
                            onChange={(e) => SetAcceptPolicy(e.target.checked)}
                        />
                        <label>
                            Jag godkänner <Link to="/policy">integritetspolicyn</Link>
                        </label>
                    </div>

                    <div className="button-container">
                        <button type="submit">Registrera</button>
                        <Link to="/login" className="login-link">
                            Logga in <KeyboardDoubleArrowRightIcon className="arrow-icon" />
                        </Link>
                    </div>
                    {successMessage && <p className="success">{successMessage}</p>}
                    {error && <p className="error">{error}</p>}
                </form>
            </div>
        </div>
    );
};

export default Register;

