import React from 'react';
import '../css/policy.css'; 

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h1>Integritetspolicy Glad Pedagog</h1>
            <hr />
            <h2>Integritetspolicy och hantering av personuppgifter</h2>
            <p><strong>Vi värnar om din integritet och säkerhet.</strong></p>
            <p>
                När du registrerar dig och köper våra onlinekurser behöver vi samla in vissa uppgifter från dig, såsom ditt namn, dina kontaktuppgifter och betalningsinformation.
                Vi är fullt engagerade i att skydda din personliga integritet och hantera dina uppgifter i enlighet med gällande lagstiftning, inklusive GDPR (General Data Protection Regulation).
            </p>
            <h3>Vilka uppgifter samlar vi in?</h3>
            <p>
                I samband med att du registrerar dig för att köpa våra kurser samlar vi in följande information:
            </p>
            <ul>
                <li>Namn</li>
                <li>E-postadress</li>
                <li>Adress (vid behov)</li>
                <li>Betalningsinformation</li>
                <li>Eventuella andra uppgifter som du frivilligt delar med oss</li>
            </ul>
            <h3>Varför samlar vi in dessa uppgifter?</h3>
            <p>Vi samlar in och behandlar dina personuppgifter för att kunna:</p>
            <ul>
                <li>Behandla din registrering och hantera din betalning.</li>
                <li>Skicka dig bekräftelser och information om de kurser du köpt.</li>
                <li>Ge dig tillgång till vårt utbildningsmaterial och vår kundtjänst.</li>
                <li>Uppfylla rättsliga krav, exempelvis bokföringsregler och skattelagar.</li>
            </ul>
            <h3>Hur lagrar vi dina uppgifter?</h3>
            <p>
                Dina personuppgifter lagras i säkra system och vi vidtar alla rimliga tekniska och organisatoriska åtgärder för att skydda dina uppgifter från obehörig åtkomst, förlust eller missbruk.
                Alla betalningsuppgifter behandlas via en säker tredjepartsbetalningstjänst och vi lagrar ingen betalningsinformation själva.
            </p>
            <h3>Delning av uppgifter med tredje part</h3>
            <p>
                Vi delar aldrig dina personuppgifter med obehöriga tredje parter utan ditt samtycke. Däremot kan vi komma att dela vissa uppgifter med tjänsteleverantörer, exempelvis för betalningshantering
                eller e-postutskick, där det är nödvändigt för att tillhandahålla våra tjänster. Dessa tredje parter är bundna av avtal att följa dataskyddslagstiftningen och hantera dina uppgifter säkert.
            </p>
            <h3>Dina rättigheter enligt GDPR</h3>
            <p>
                Som användare har du rättigheter enligt GDPR som säkerställer att du har kontroll över dina personuppgifter. Du har rätt att:
            </p>
            <ul>
                <li>Få tillgång till de uppgifter vi har om dig.</li>
                <li>Begära att vi rättar felaktiga uppgifter.</li>
                <li>Begära att vi raderar dina uppgifter, under förutsättning att vi inte har en rättslig skyldighet att behålla dem.</li>
                <li>Begränsa behandlingen av dina uppgifter.</li>
                <li>Invända mot att vi använder dina uppgifter i vissa syften, exempelvis marknadsföring.</li>
                <li>Flytta dina uppgifter till en annan tjänst (dataportabilitet).</li>
            </ul>
            <p>
                Om du vill utöva någon av dina rättigheter kan du kontakta oss på mailadress <a href="mailto:info@gladpedagog.se">info@gladpedagog.se</a>.
            </p>
            <h3>Hur länge sparar vi dina uppgifter?</h3>
            <p>
                Vi sparar dina personuppgifter så länge de behövs för att fullgöra de ändamål som beskrivs ovan, eller så länge vi är skyldiga att göra det enligt lag. När dina uppgifter inte längre behövs, raderar vi dem på ett säkert sätt.
            </p>
            <h3>Kontakt och frågor</h3>
            <p>
                Om du har några frågor om hur vi hanterar dina personuppgifter eller vill utöva någon av dina rättigheter, vänligen kontakta oss på:
            </p>
            <p>E-post: <a href="mailto:info@gladpedagog.se">info@gladpedagog.se</a></p>
        </div>
    );
};

export default PrivacyPolicy;
